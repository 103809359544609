<template>
  <div class="full-container test-container">
    <title-header :title="'平板锁生产测试'"/>

    <div class="page-container">
      <ul class="flex-column fields-box">

        <li class="fields-list">
          <p class="fields-item-label">测试项目参数设置：</p>
          <div class="flex-no-wrap fields-item">
<!--            <input type="text" class="fields-input" v-model="itemTestString" style="width: 70%" disabled>-->
            <div class="fields-input" @click="controlModal(true)">{{itemTestString}}</div>
            <div class="bg fields-btn" @click="controlModal(true)">设置</div>
          </div>
        </li>
        <li class="fields-list">
          <p class="fields-item-label">请输入待输入锁号：(一批次最多10台)</p>
          <div class="flex-no-wrap fields-item">
            <md-icon name="scan" size="lg" @click="controlScanModal(true)"></md-icon>
            <input type="number" class="fields-input" v-model="currentCode">
            <div class="bg fields-btn" @click="addToTable">添加</div>
          </div>
        </li>

      </ul>


      <table cellspacing="0" cellpadding="0" class="test-table">
        <tr class="tr-header">
          <th style="width: 20%;">锁号</th>
          <th>测试项</th>
          <th>状态</th>
        </tr>
        <tr class="tr-body" v-for="(item, lockIndex) in tableList" v-if="tableList.length !== 0" :key="lockIndex">
          <td>
            <div class="flex-vertical">
              <span>{{ item.lockNo }}</span>
            </div>

          </td>
          <td>
            <div class="config-td" v-for="(lock, index) in item.configs" :key="index">
              <div class="flex-vertical">
                <md-icon name="right" size="md" v-if="lock.status === 0"></md-icon>
                <md-icon name="wrong" size="md" v-if="lock.status === 1"></md-icon>
                <md-icon name="spinner" size="md" v-if="lock.status === 2" style="-webkit-filter:invert(1)"></md-icon>
                <span>{{ translateLabel(lock.dataType) }}:</span>
                <span style="color: #13c2c2">{{ lock.desc }}</span>
              </div>

              <div class="hand-btn-box" v-if="lock.dataType === '242' && lock.status === 2">
                <md-button type="primary" size="small" inline class="hand-btn" @click="setConfig(lockIndex, 0)">正确</md-button>
                <md-button type="warning" size="small" inline class="hand-btn" @click="setConfig(lockIndex, 1)">错误</md-button>
              </div>
            </div>
          </td>
          <td>
            <div class="flex-vertical">
              <md-icon name="right" size="lg" v-if="item.status === 0"></md-icon>
              <md-icon name="wrong" size="lg" v-if="item.status === 1"></md-icon>
              <md-icon name="spinner" size="lg" v-if="item.status === 2" style="-webkit-filter:invert(1)"></md-icon>
              <span class="action" style="color: #eb2f96">{{item.action}}</span>
            </div>
          </td>
        </tr>
        <tr v-if="tableList.length === 0">
          <td style="text-align: center" colspan="3">暂无数据</td>
        </tr>
      </table>


      <md-button type="primary" class="test-btn" @click="confirmDoTest" v-if="!isTest">开始测试</md-button>
      <md-button type="primary" class="test-btn" @click="confirmFinishTest" v-else>结束测试 {{countDown}}</md-button>
    </div>

<!--  测试配置项的Modal框  -->
    <config-modal :visible="visible" :list="checkboxList" @close="controlModal" @getConfigSetting="getConfigSetting"/>
    <scan v-if="isScan" @closeScan="controlScanModal(false)" @getCode="getScanCode"/>
  </div>
</template>

<script>
import TitleHeader from "../../components/TitleHeader";
import Scan from "../../components/Scan";
import {Dialog, Toast} from "mand-mobile";
import axios from "axios";
import Api from "./../../assets/config/api.config";
import ConfigModal from "../../components/configModal";

export default {
  name: "doTest",
  components: {
    TitleHeader,
    Scan,
    ConfigModal,
  },
  data() {
    return {
      testConfigList: [],
      visible: false,
      isScan: false,
      checkboxList: [],
      itemTestString: '', // checkbox选择之后的string
      tableList: [],
      currentCode: '',
      isDuplicate: false,  // 是否存在相同的锁号项
      currentTestItemIndex: 0,  // 当前正在检测的锁号
      isTest: false,  // 判断是否已经测试过
      during: 6000,  // 轮询间隔
      overtimes: 60, // 超时次数
      needFinish: false,
      countTimer: 0,
      countDown: '00:00', // 计时
      t: null,
      processEnd: false,
    }
  },
  created() {
    this.getConfigList();
  },
  methods: {
    doCountTime() {
      if(this.needFinish) {
        clearTimeout(this.t);
        this.t = null;
        this.countTimer = 0;
        this.countDown = '00:00';
      }else {
        this.t = setTimeout(() => {
          this.countTimer++;
          this.countDown = this.addNumberZero(Math.floor(this.countTimer / 60)) + ':' + this.addNumberZero(this.countTimer % 60);
          this.doCountTime();
        }, 1000)
      }
      
    },
    addNumberZero(num) {
      if(num >= 10) {
        return num.toString();
      }else {
        return '0' + num;
      }
    },
    // 手动设置测试项的状态
    setConfig(lockIndex, value) {
      this.tableList[lockIndex].configs[this.tableList[lockIndex].configs.length - 1].status = value;
      this.tableList[lockIndex].configs[this.tableList[lockIndex].configs.length - 1].desc = '手动设置';
      this.tableList[lockIndex].status = value;
      this.tableList[lockIndex].action = '';
      this.isTest = false;
      clearTimeout(this.countTimer);
    },
    // 控制scan的modal的显示与隐藏
    controlScanModal(bool) {
      this.isScan = bool;
    },
    // 控制config Modal的显示
    controlModal(bool) {
      this.visible = bool;
    },
    // 获取选择的配置项
    getConfigSetting(item) {
      this.testConfigList = this.sortConfigSetting(item);  // 强制设置成为特定的顺序
      let str = '';
      this.testConfigList.map(config => {
        str += this.translateLabel(config) + ';'
      })
      this.itemTestString = str;
      this.controlModal(false);
    },
    // 对已选择的进行排序
    sortConfigSetting(item) {
      const order = [7, 26, 1, 0, 243, 241, 240, 244, 242];
      let orderArr = [];
      for(let i = 0; i < item.length; i++) {
        for(let j = 0; j < order.length; j++) {
          if(parseInt(item[i]) === order[j]) {
            orderArr.push(j);
          }
        }
      }
      let sortOrderArr = orderArr.sort();
      let resultConfigList = [];
      sortOrderArr.map(a => {
        resultConfigList.push(order[a].toString());
      })
      return resultConfigList;
    },

    // 获取扫码结果
    getScanCode(code) {
      Toast.info('解析成功')
      this.isScan = false;
      this.currentCode = this.filterLock(code);

    },
    // 从二维码中过滤出锁号
    filterLock(string, params='lock') {
      if(string) {
        let search = string.split('?')[1];
        return search.replace(params + '=', '');
      }
    },
    // 添加检查项
    addToTable() {
      if(this.tableList.length > 10) {
        Toast.info('一批次最多添加10台检测');
        return false;
      }
      if(this.isTest) {
        this.tableList = [];
        this.isTest = false;
        clearTimeout(this.countTimer);
      }
      this.checkDuplicateLockNo(this.currentCode);
      if(!this.currentCode) {
        Toast.info('请先输入或扫描锁号');
        return false;
      }
      if(this.testConfigList.length === 0) {
        Toast.info('请先选择测试配置项');
        return false;
      }
      if(this.isDuplicate) {
        Toast.info('已存在相同的锁号，请更换');
        return false;
      }
      let tempConfigs = [];
      if(this.testConfigList.length > 0) {
        for(let i = 0; i < this.testConfigList.length; i++) {
          tempConfigs.push({
            dataType: this.testConfigList[i],
            status: -1,
            desc: '',
            count: 0, // 计数
            success: 0 // 对于成功的次数计数
          })
        }
      }
      this.tableList.push({
        createTime: this.renderCurrentDate(),
        lockNo: this.currentCode,
        configs: tempConfigs,
        settingItem: this.itemTestString,
        status: -1,
        action: '',  // 操作指令
      });
      Toast.info('添加成功');
      this.currentCode = '';
    },
    // 获取当前的时间
    renderCurrentDate() {
      let current = new Date();
      let year = current.getFullYear();
      let month = this.addNumberZero(current.getMonth() + 1);
      let date = this.addNumberZero(current.getDate());
      let hours = this.addNumberZero(current.getHours());
      let minutes = this.addNumberZero(current.getMinutes());
      let seconds = this.addNumberZero(current.getSeconds());
      return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    // 判断列表中是否存在锁号
    checkDuplicateLockNo(lock) {
      this.isDuplicate = false;
      if(this.tableList.length > 0) {
        this.tableList.map(table => {
          if(table.lockNo.toString() === lock) {
            this.isDuplicate = true;
            return false;
          }
        })
      }

    },
    // 转化为汉字
    translateLabel(value, list = [], key='name') {
      let str = ''
      this.checkboxList.map(l => {
        if(l.systemId === value) {
          str = l.name;
        }
      })
      return str;
    },
    // 接口获取配置项信息
    getConfigList() {
      axios.post(Api.configList, {}).then(res => {
        if(res.data.code === 200) {
          this.checkboxList = res.data.data.items;
        }
      }).catch(err => {
        if(err.response.status === 401) {
          Toast.info("请重新登录");
          this.$router.push({path: '/login'});
        }
      })
    },
    // 确认是否测试
    confirmDoTest() {
      Dialog.confirm({
        title: '确认',
        content: '请确认是否进行测试',
        confirmText: '确定',
        onConfirm: () => this.doTest(),
      })

    },
    // 开始测试
    doTest() {
      if(this.tableList.length === 0) {
        Toast.info('请添加需要测试的锁号和配置项');
        return false;
      }
      this.needFinish = false;
      // 计时开始
      this.doCountTime();
      for(let lockIndex = 0; lockIndex < this.tableList.length; lockIndex++) {
        let params = {
          sn: this.tableList[lockIndex].lockNo,
          configs: this.testConfigList[0], // 默认第一项
          beginTime: new Date().valueOf()
        }
        this.doTestEveryConfigItem(params, lockIndex);
      }

    },
    doExtractConfig(params, code) {
      let data = {
        sn: params.sn,
        configs: code,// 执行特殊的项目
        beginTime: new Date().valueOf()
      }
      axios.post(Api.startQa, data).then(res => {
        console.log('特殊项目执行：', code);
      })
    },
    // 测试每一项测试项
    doTestEveryConfigItem(params, lockIndex, configIndex = 0) {
      if(this.needFinish) {
        return false; // 结束进程
      }
      params.configs = this.testConfigList[configIndex];
      // if(params.configs === '241' || params.configs === '240') { // 有车、无车特殊处理
      if(params.configs === '240') { // 有车、无车特殊处理
        if(this.tableList[lockIndex].configs[configIndex].count < 1) {
          this.doExtractConfig(params, '243');
        }
      }
      if(params.configs === '242') { // 手动的检测项目
        this.isTest = true;
        this.tableList[lockIndex].status = 2;
        this.tableList[lockIndex].configs[configIndex].status = 2;
        return false;
      }
      axios.post(Api.startQa, params).then(res => {
        let timer = null;
        this.isTest = true;
        if(res.data.code === 200) {
          if(res.data.data.items[0].dataType === this.testConfigList[configIndex]) {
            if(res.data.data.items[0].status === 2) { // pending的状态
              this.tableList[lockIndex].status = 2;  // 等待继续操作
              this.tableList[lockIndex].configs[configIndex].status = 2;
              this.tableList[lockIndex].action = res.data.msg;
              // 计数
              this.tableList[lockIndex].configs[configIndex].count++;
              if(res.data.data.items[0].dataType === '241') { // 有车的判断
                this.testCarStatus(res.data.data.items[0].desc, '有车', '无车', params, lockIndex, configIndex, 10);
                if(this.processEnd) {
                  return false;
                }
                // 特殊处理设备服务器没有数据返回时的状态
                if(res.data.data.items[0].desc === '不是最新数据或' + params.sn +'设备不在线,(确保设备在线,重新获取)') {
                  this.doExtractConfig(params, '243');
                }
              }
              if(res.data.data.items[0].dataType === '240') { // 无车的判断
                this.testCarStatus(res.data.data.items[0].desc, '无车', '有车', params, lockIndex, configIndex, 5);
                if(this.processEnd) {
                  return false;
                }
              }
              if(this.tableList[lockIndex].configs[configIndex].count < this.overtimes) {
                timer = setTimeout(() => {
                  this.doTestEveryConfigItem(params, lockIndex, configIndex);
                }, this.during);
              }else {
                // 如果超过了规定的请求参数，则就把所有没有结果的进程设置为失败
                let index = configIndex;
                for(let i = index; i < this.testConfigList.length; i++) {
                  if(this.tableList[lockIndex].configs[index].status !== 0) {
                    this.tableList[lockIndex].configs[index].status = 1;
                    this.tableList[lockIndex].configs[index].desc = '超时';
                  }
                  this.tableList[lockIndex].status = 1;
                  this.tableList[lockIndex].action = '';
                  this.isTest = false;
                  clearTimeout(this.countTimer);
                  this.insertHistoryList();
                }
              }
            }else {
              clearTimeout(timer); // 清除定时器
              this.tableList[lockIndex].action = ''; // 清空指令
              this.tableList[lockIndex].status = this.testConfigList.length > 1 ? 2 : res.data.data.items[0].status;
              this.tableList[lockIndex].configs[configIndex].status = res.data.data.items[0].status;
              this.tableList[lockIndex].configs[configIndex].desc = res.data.data.items[0].desc;
              if(res.data.data.items[0].status === 1) {  // 只要有一项是失败的，就不继续了
                this.tableList[lockIndex].status = 1; // 最后状态也显示为失败
                // 将后续所有的测试项都设置为失败
                let index = configIndex;
                for(let i = index; i < this.testConfigList.length; i++) {
                  this.tableList[lockIndex].configs[index].status = 1;
                }
                this.isTest = false;
                clearTimeout(this.countTimer);
                this.insertHistoryList();
                return false;
              }
              if(res.data.data.items[0].status === 0) {  // 开始测试下一个项目
                this.setTestItemSuccess(params, lockIndex, configIndex);
              }
            }
          }
        }else {
          Toast.info(res.data.msg);
          if(res.data.code === 401) {
            this.$router.push({path: '/login'})
          }
        }

      }).catch(err => {
        // this.doTestEveryConfigItem(params, lockIndex, configIndex);
        if(err.response.status === 401) {
          Toast.info("请重新登录");
          this.$router.push({path: '/login'});
        }
      })
    },
    // 检测有车/无车的状态
    testCarStatus(desc, correct, wrong, params, lockIndex, configIndex, succesCount) {
      this.processEnd = false;
      if(desc === correct) {
        this.tableList[lockIndex].configs[configIndex].success++;
        this.tableList[lockIndex].configs[configIndex].desc = correct + '+' + this.tableList[lockIndex].configs[configIndex].success;
        console.log('是否有车', this.tableList[lockIndex].configs[configIndex].success)
        if(this.tableList[lockIndex].configs[configIndex].success === succesCount) {
          this.tableList[lockIndex].configs[configIndex].status = 0;
          this.tableList[lockIndex].configs[configIndex].desc = '检测成功';
          this.setTestItemSuccess(params, lockIndex, configIndex);
          // 无车判断之后，调用另外的流程
          if(correct === '无车') {
            this.doExtractConfig(params, '244');
          }

          this.processEnd = true;
        }
      }else if(desc === wrong){
        this.tableList[lockIndex].configs[configIndex].success = 0;
        this.tableList[lockIndex].configs[configIndex].desc = desc;
      }
    },
    // 抽象设置成功状态
    setTestItemSuccess(params, lockIndex, configIndex) {
      this.tableList[lockIndex].configs[configIndex].status = 0; // 标注状态
      if(configIndex + 1 < this.testConfigList.length) {
        params.beginTime = new Date().valueOf();
        this.doTestEveryConfigItem(params, lockIndex, configIndex + 1);
      }else {
        this.tableList[lockIndex].status = 0;  // 表示所有的项目成功
        this.tableList[lockIndex].action = '';  // 清空指令
        this.isTest = false;
        clearTimeout(this.countTimer);
        this.insertHistoryList();
      }
    },
    // 主动结束测试
    confirmFinishTest() {
      Dialog.confirm({
        title: '确认',
        content: '确认是否取消测试',
        confirmText: '确定',
        onConfirm: () => this.finishTest(),
      })
    },
    finishTest() {
      // 停止所有的进程
      this.needFinish = true;
      this.isTest = false;
      clearTimeout(this.countTimer);
      Toast.info('正在停止，请稍后')
      for(let i = 0; i < this.tableList.length; i++) {
        if(this.tableList[i].status !== 0) {
          this.tableList[i].status = 1;
          this.tableList[i].action = '';
          this.tableList[i].configs.map(c => {
            if(c.status !== 0) {
              c.status = 1;
              c.count = 0;
              c.desc = '手动结束';
              c.succes = 0;
            }
          })
        }

      }
      this.insertHistoryList();

    },
    insertHistoryList() {
      let params = [];
      this.tableList.map(list => {
        params.push({
          user: localStorage.getItem('user'),
          createTime: list.createTime,
          lockNo: list.lockNo,
          state: list.status,
          data: JSON.stringify(list.configs)
        })
      })
      axios.post(Api.insertHistory, params).then(res => {
        console.log('记录添加成功');
      })
    },
  }
}
</script>

<style src="./doTest.less" lang="less"></style>
