<template>
  <!--  测试配置项的Modal框  -->
  <div class="select-item-modal" v-if="visible">
    <div class="item-modal-box">
      <md-icon :name="'close'" size="lg" class="close-btn" @click="controlModal(false)"></md-icon>
      <div class="modal-title">测试项目设定</div>
      <md-check-group v-model="checkResult">
        <md-check size="lg" :name="item.systemId" :label="`${item.name}[${item.systemId}]`" v-for="(item, index) in list" :key="index"/>
      </md-check-group>
      <md-button type="primary" class="setting-btn" @click="confirmSetting">确认设置</md-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "configModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      checkResult: []
    }
  },
  methods: {
    // 控制Modal框显示
    controlModal(bool) {
      this.$emit('close', bool);
    },
    // 确认设置项
    confirmSetting() {
      this.$emit('getConfigSetting', this.checkResult)
    }
  },
  watch: {
    list: function(val) {
      let arr = val;
      let result = [];
      arr.map(a => {
        result.push(a.systemId);
      })
      this.checkResult = result;
    }
  }
}
</script>

<style scoped>

</style>
